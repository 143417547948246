import { useReactTable, getCoreRowModel, flexRender, createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import { useEffect, useState } from "react";
import * as FileService from "../services/fileService";
import IntegrationAnnouncementService from "../services/integrationAnnouncementService";
import SettingsService from "../services/settingsService";

import { toast } from "react-toastify";
import config from "../config.json";
import dictionary from "../PL.json";
import Tags from "./tags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmationSwitch from "./common/confirmationSwitch";
import AddOrEditAnnouncementIntegrationModal from "./AddOrEditAnnouncementIntegrationModal";
import ConfirmationIconWithToolTip from "./common/confirmationIconWithToolTip";

import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";

const columnHelper = createColumnHelper();

const KEY = "integration_api_key";

const AnnouncementsTable = () => {
  const [apiKey, setApiKey] = useState({
    name: "KEY",
    value: { api_key: "none" },
    enable: false,
  });
  const [files, setFiles] = useState([]);
  const [announcements, setAnnouncements] = useState([]);
  const [showAddOrEdit, setShowAddOrEdit] = useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);

  const [testAnnouncement, setTestAnnouncement] = useState("1");
  const [testShop, setTestShop] = useState("1328");

  const setEdit = (id) => {
    if (id !== null) {
      const announcement = announcements.find((item) => item._id === id);
      setSelectedAnnouncement({ ...announcement });
    } else {
      setSelectedAnnouncement(null);
    }
    setShowAddOrEdit(true);
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor("lp", {
        header: "lp",
        cell: (cell) => cell.row.index + 1,
      }),

      columnHelper.accessor("name", { header: dictionary.integration.tabs_announcements.table.name }),
      columnHelper.accessor("file", {
        header: "Plik",
        cell: (cell) => cell.getValue().name,
      }),
      columnHelper.accessor("devices", {
        header: dictionary.integration.tabs_announcements.table.devices,
        cell: (item) => {
          return (
            <div className="tag-container flex-container__center">
              {item.getValue().map((device) => {
                return (
                  <div
                    className="tag"
                    key={device}
                  >
                    <div className="tag__name">{device}</div>
                  </div>
                );
              })}
            </div>
          );
        },
      }),
      columnHelper.accessor("zones", {
        header: dictionary.integration.tabs_announcements.table.zones,
        cell: (item) => {
          return (
            <div className="tag-container flex-container__center">
              {item.getValue().map((zone) => {
                return (
                  <div
                    className="tag"
                    key={zone}
                  >
                    <div className="tag__name">{dictionary.integration.tabs_announcements.zoneMASettings[zone]}</div>
                  </div>
                );
              })}
            </div>
          );
        },
      }),
      columnHelper.accessor("integration_id", {
        header: dictionary.integration.tabs_announcements.table.integration_id,
      }),
      columnHelper.accessor("active", {
        header: dictionary.integration.tabs_announcements.table.active,
        cell: (item) => {
          return (
            <ConfirmationSwitch
              label={
                item.getValue()
                  ? dictionary.apiSettings.CheckSwitch_deactivateConfirmLabel
                  : dictionary.apiSettings.CheckSwitch_activateConfirmLabel
              }
              yesLabel={dictionary.apiSettings.CheckSwitch_confirmYesLabel}
              noLabel={dictionary.apiSettings.CheckSwitch_confirmNoLabel}
              key={item.row.original._id + "active"}
              onChange={(event) => enableAnnouncement(event, item.row.original._id)}
              type="switch"
              checked={item.getValue()}
            />
          );
        },
      }),
      columnHelper.accessor("actions", {
        header: () => {
          return (
            <Button onClick={() => setEdit(null)}>{dictionary.integration.tabs_announcements.table.addNew}</Button>
          );
        },
        cell: (item) => {
          //get rest of row data

          return (
            <>
              <FontAwesomeIcon
                onClick={() => setEdit(item.row.original._id)}
                className="tag__edit color-green my-0"
                icon={faEdit}
              />
              <ConfirmationIconWithToolTip
                placement="top"
                info={""}
                label={dictionary.integration.apiKey.saveButton_confirmLabel}
                yesLabel={dictionary.integration.apiKey.saveButton_confirmYesLabel}
                noLabel={dictionary.integration.apiKey.saveButton_confirmNoLabel}
                onClick={() => removeItem(item.row.original._id)}
                className="tag__delete color-red my-0"
                icon={faTrash}
              />
            </>
          );
        },
      }),
    ],
    [announcements]
  );

  const fetchData = async () => {
    try {
      const { data: announcementFiles } = await FileService.getAll();
      setFiles(announcementFiles);

      const { data: integrations } = await IntegrationAnnouncementService.getAll();

      setAnnouncements(integrations);

      let { data } = await SettingsService.getSetting(KEY);
      if (data === "") {
        setApiKey({
          name: "KEY",
          value: { api_key: "none" },
          enable: false,
        });
      } else {
        setApiKey({
          name: data.name,
          value: data.value,
          enable: data.enable,
        });
      }
    } catch (err) {
      toast.error("Błąd serwera!!!");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const table = useReactTable({
    data: announcements,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const removeItem = async (id) => {
    try {
      await IntegrationAnnouncementService.remove(id);
      await fetchData();
    } catch (ex) {
      toast.error("Błąd serwera!!!", { autoClose: 2000, pauseOnFocusLoss: false });
    }
  };

  const enableAnnouncement = async (value, id) => {
    try {
      const { data: announcement } = await IntegrationAnnouncementService.update(id, { active: value });
      await fetchData();
    } catch (ex) {
      toast.error("Błąd serwera!!!", { autoClose: 2000, pauseOnFocusLoss: false });
    }
  };

  async function handleTestAnnouncement() {
    try {
      const announcement = announcements.find((item) => item._id === testAnnouncement);
      if (announcement === undefined) return;
      const { data } = await IntegrationAnnouncementService.testAnnouncement(
        apiKey.value.api_key,
        announcement.integration_id,
        testShop
      );
      toast.success("Ogłoszenie testowe wysłane do sklepu",{autoClose: 2000, pauseOnFocusLoss: false});
    } catch (err) {
      toast.error(err.response.data,{autoClose: 2000, pauseOnFocusLoss: false});
    }
  }

  return (
    <div className="table-responsive">
      <div className="row my-3 mx-auto">
        <div className="col">
          <select
            className="form-select"
            aria-label="Default select example"
            value={testAnnouncement}
            onChange={(e) => setTestAnnouncement(e.target.value)}
          >
            <option key="1">Wybierz ogłoszenie</option>
            {announcements.map((announcement) => (
              <option
                key={announcement._id}
                value={announcement._id}
              >
                {announcement.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col">
          <input
            type="text"
            className="form-control"
            placeholder="Numer sklepu"
            value={testShop ? testShop : ""}
            onChange={(e) => setTestShop(e.target.value)}
          />
        </div>
        <div className="col">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => handleTestAnnouncement()}
          >
            Testuj
          </button>
        </div>
      </div>
      <table className="table table-striped table-bordered align-middle text-center">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  className="text-center align-middle"
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className="text-center"
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      <AddOrEditAnnouncementIntegrationModal
        show={showAddOrEdit}
        onHide={() => {
          setShowAddOrEdit(false);
          fetchData();
        }}
        announcement={selectedAnnouncement}
        files={files}
      />
    </div>
  );
};

export default AnnouncementsTable;
