import React from "react";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { useConfirmationModalContext } from "./modalConfirmationContext";

const ConfirmationIconWithToolTip = (props) => {
  const { className, onClick, label, yesLabel, noLabel, body, placement, info, ...rest } = props;
  const modalContext = useConfirmationModalContext();

  const handleOnClick = async (e) => {
    e.stopPropagation();
    modalContext.setLabel(label);
    modalContext.setYesLabel(yesLabel);
    modalContext.setNoLabel(noLabel);
    body ? modalContext.setBody(body) : modalContext.setBody("");
    const result = await modalContext.showConfirmation();
    result && onClick();
  };

  if (info !== "") {
    return (
      <OverlayTrigger
        placement={placement}
        overlay={
          <Tooltip id="tooltip">
            <div>{info}</div>
          </Tooltip>
        }
      >
        <FontAwesomeIcon
          className={className}
          onClick={handleOnClick}
          {...rest}
        ></FontAwesomeIcon>
      </OverlayTrigger>
    );
  } else {
    return (
      <FontAwesomeIcon
        className={className}
        onClick={handleOnClick}
        {...rest}
      ></FontAwesomeIcon>
    );
  }
};

export default ConfirmationIconWithToolTip;
