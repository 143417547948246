import http from "./httpService";

import config from "../config.json";

const apiEndpoint = "/api/integrations/announcement";

export function add(data) {
  return http.post(apiEndpoint + "/", data);
}

export async function update(id, data) {
  return http.put(apiEndpoint + "/" + id, data);
}

export function getAll() {
  return http.get(apiEndpoint);
}

export function remove(id) {
  return http.delete(apiEndpoint + "/" + id);
}

export function testAnnouncement(api_key, integration_id, shop_no) {
  // console.log("/api/integrations/v1/announcement/" + integration_id + "/shop/" + shop_no);
  return http.post(
    "/api/integrations/v1/announcement/" + integration_id + "/shop/" + shop_no,
    {},
    {
      headers: {
        "api-key": api_key,
      },
    } 
  );
}

const IntegratonAnnouncementSerivice = { getAll, add, update, remove, testAnnouncement };

export default IntegratonAnnouncementSerivice;
