import React, { Component } from "react";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";

import CardDeviceHeader from "./cardDevice/cardDeviceHeader";
import CardDeviceButtonsPanel from "./cardDevice/cardDeviceButtonsPanel";
import CardDeviceNameView from "./cardDevice/cardDeviceNameView";
import CardDeviceTagView from "./cardDevice/cardDeviceTagView";
import CardDeviceWifiView from "./cardDevice/cardDeviceWifiView";
import CardDeviceFooter from "./cardDevice/cardDeviceFooter";

import VolumeSettingsBlockMAS from "./confControl/volumeSettingsBlockMAS";
import VolumeSettingsBlock from "./confControl/volumeSettingsBlock";

import * as wsCommunication from "../../utils/wsCommunication";

import * as AuthDeviceService from "../../services/authDeviceService";
import * as deviceConf from "../../utils/conf/deviceConf";
import { toast } from "react-toastify";

import config from "../../config.json";
import Form from "react-bootstrap/Form";
import SimpleSelect from "./simpleSelect";
import ConfirmationIcon from "./confirmationIcon";
import dictionary from "../../PL.json";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import VolumeSettingsBlockNumberInput from "./confControl/volumeSettingsBlockNumberInput";
import VolumeSettingsBlockTableHeaderNumberInput from "./confControl/volumeSettingsBlockTableHeaderNumberInput";
import VolumeToAll from "./confControl/volumeToAll";
import { deviceMessage } from "../../utils/wsCommunication";

class CardDeviceMAS extends Component /* ({ device, online, connectionStatus })  */ {
  state = {
    expanded: false,
    volume: 1,
    audioEnable: false,
  };

  componentDidMount = () => {
    const { device } = this.props;

    this.updateSettings(device);
  };

  updateSettings(device) {
    const { volume, audioEnable } = deviceConf.AudioDeviceVOCOVOSettingsGenerateAndFillWithData(device);

    this.setState({
      volume,
      audioEnable,
    });
  }

  handleVolumeChange = (volume) => {
    this.setState({
      volume: Number(volume),
    });
  };

  handleEnableChange = (value) => {
    this.setState({
      audioEnable: value,
    });
  };

  handleSendConfiguration = async () => {
    const { device } = this.props;
    const { volume, audioEnable } = this.state;

    let conf = {};

    conf = {
      volume: volume,
      audioEnable: audioEnable,
    };

    try {
      const { data } = await AuthDeviceService.postConf({
        _id: this.props.device._id,
        conf,
      });

      wsCommunication.forceStatusMessage(this.props.device.sn, this.props.onMessageToSent);

      toast.success("Nowe ustawienia zostały zapisane!");

      this.updateSettings(data);
    } catch (err) {
      toast.error("Błąd komunikacji z serwerem");
    }
  };

  handleDemoAction = (data) => {
    wsCommunication.demoMessage(this.props.device.sn, data, this.props.onMessageToSent, this.props.onMessageToSent);
  };

  handleDelete = async () => {
    const { data } = await AuthDeviceService.remove(this.props.device._id);

    this.props.forceDataRefresh();
  };

  handleExpand = () => {};

  render() {
    const { device, onlineDeviceData } = this.props;
    const { expanded, volume, audioEnable } = this.state;

    return (
      <Card
        className="mb-2 cursor-pointer"
        style={{ minWidth: "800px" }}
      >
        <CardDeviceHeader
          expanded={expanded}
          onlineDeviceData={onlineDeviceData}
          onAction={this.handleDemoAction}
          device={device}
          onExpandedClick={() => {
            this.setState({ expanded: ~expanded });
          }}
        />

        {expanded ? (
          <React.Fragment>
            <Card.Body>
              <CardDeviceNameView
                device={device}
                refreshData={this.props.forceDataRefresh}
              ></CardDeviceNameView>
              <CardDeviceTagView
                device={device}
                refreshData={this.props.forceDataRefresh}
              ></CardDeviceTagView>
              <CardDeviceWifiView
                device={device}
                refreshData={this.props.forceDataRefresh}
              ></CardDeviceWifiView>

              <hr style={{ width: "100%", margin: "auto", background: "black" }}></hr>

           
                <VolumeSettingsBlockMAS
                  value={{
                    volume: volume,
                    audioEnable: audioEnable,
                  }}
                  onVolumeChange={this.handleVolumeChange}
                  onEnableChange={this.handleEnableChange}
                  device={device}
                  label={"Głośność (1-20):"}
                />



              <CardDeviceButtonsPanel
                onDelete={this.handleDelete}
                onSendConfiguration={this.handleSendConfiguration}
                onDeleteAllMusicFiles={this.handleDeleteAllMusicFiles}
                onForceIMSPlaylistsUpdate={this.handleForceIMSPlaylistsUpdate}
              />
            </Card.Body>
          </React.Fragment>
        ) : null}

        <CardDeviceFooter
          device={device}
          onlineDeviceData={onlineDeviceData}
          onChangeConf={this.handleDemoAction}
        />
      </Card>
    );
  }
}

export default CardDeviceMAS;
