import React, {Component} from "react";
import {Link, NavLink} from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStore as store} from "@fortawesome/free-solid-svg-icons";
import {faWrench as wrench} from "@fortawesome/free-solid-svg-icons";

import {isAdmin, isAdminOrIsService, isService} from "../services/authService";
import logo from "../logo.svg";
import logoProdel from "../logoProdel.png";
import MusicManagement from "./musicManagement";

class NavBar extends Component {
    state = {};

    render() {
        const user = this.props.user;
        return (
            <Navbar bg="dark" expand="lg" variant="dark" className="p-2">
                <Navbar.Brand href="/shops">
                    {/* <img src={logo} width="190" height="50" className="d-inline-block align-top" alt="ROSS"/> */}
                </Navbar.Brand>
                <Navbar.Brand href="/shops">
                    <img
                        src={logoProdel}
                        width="132"
                        height="45"
                        className="d-inline-block align-top"
                        alt="RetSens"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        {user && (
                            <React.Fragment>
                                <Nav.Link href="/shops">
                                    Sklepy
                                    {/* Sklepy <FontAwesomeIcon icon={store} size="1x" /> */}
                                </Nav.Link>
                                <Nav.Link href="/authDevices">Lista urządzeń</Nav.Link>
                                {isAdminOrIsService() && (
                                    <NavDropdown title="Serwis" id="basic-nav-dropdown">
                                        <NavDropdown.Item href="/unauthdevices">Poczekalnia</NavDropdown.Item>
                                        <NavDropdown.Item href="/logs">Logi</NavDropdown.Item>

                                    </NavDropdown>
                                )}

                                {isAdmin() && (
                                    <NavDropdown title="Konfiguracja" id="basic-nav-dropdown">
                                        <NavDropdown.Item href="/files">Zarządzanie plikami mp3</NavDropdown.Item>
                                        <NavDropdown.Divider/>
                                        <NavDropdown.Item href="/fwFiles">
                                            Zarządzanie plikami firmwaru
                                        </NavDropdown.Item>
                                        <NavDropdown.Item href="/devicesupdate">
                                            Zarządzanie aktualizacją firmwaru
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider/>
                                        <NavDropdown.Item href="/integration">
                                            Integracja
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider/>
                                        <NavDropdown.Item href="/wifiSettings">
                                            Zarządzanie sieciami WiFi
                                        </NavDropdown.Item>
                                        <NavDropdown.Item href="/settingsApi">
                                            Ustawienia API
                                        </NavDropdown.Item>
                                        <NavDropdown.Item href="/restartSettings">
                                            Ustawienia restartów
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider/>
                                        <NavDropdown.Item href="/tags">
                                            Zarządzanie tagami
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider/>
                                        <NavDropdown.Item href="/users">Zarządzanie użytkownikami</NavDropdown.Item>
                                    </NavDropdown>
                                )}

                                {isAdmin() && (
                                    <NavDropdown title="Zarządzanie muzyką" id="basic-nav-dropdown">
                                        <NavDropdown.Item href="/playlisty">Playlisty</NavDropdown.Item>
                                        <NavDropdown.Item href="/musicinshops">Sklepy</NavDropdown.Item>
                                        <NavDropdown.Item href="/audioplayerHistory">Historia</NavDropdown.Item>
                                    </NavDropdown>
                                )}

                            </React.Fragment>
                        )}
                    </Nav>

                    <Nav>
                        {/* <Nav.Link href="/register">Zarejestruj</Nav.Link> */}
                        <Nav.Link>{"v." + process.env.REACT_APP_VERSION}</Nav.Link>
                        {!user && <Nav.Link href="/Login">Zaloguj</Nav.Link>}
                        {user && (
                            <React.Fragment>
                                <Nav.Link>{user.username}</Nav.Link>
                                <Nav.Link href="/Logout">Wyloguj</Nav.Link>
                            </React.Fragment>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

export default NavBar;
