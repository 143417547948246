import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, Form, Button, Toast } from "react-bootstrap";
import dictionary from "../PL.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import SimpleSelect from "./common/simpleSelect";
import IntegrationAnnouncementService from "../services/integrationAnnouncementService";
import { toast } from "react-toastify";


const AddOrEditAnnouncementIntegrationModal = ({ show, onHide, announcement, files }) => {
  const isEdit = announcement !== null;

  const [editedAnnouncement, setEditedAnnouncement] = useState({
    name: "",
    devices: [],
    zones: [],
    file: { _id: "" },
    active: false,
  });
  const [errors, setErrors] = useState({
    name: false,
    devices: false,
    zones: false,
    file: false,
  });
  const [selectedDevices, setSelectedDevices] = useState(null);

  function handleShow() {
    console.log(files);
    console.log(announcement);
    if (announcement === null) {
      setEditedAnnouncement({
        name: "",
        devices: [],
        zones: [],
        file: { _id: "" },
        active: false,
      });
    } else {
      setEditedAnnouncement({
        name: announcement.name,
        devices: announcement.devices,
        zones: announcement.zones,
        file: announcement.file,
        active: announcement.active,
      });
    }

    // setErrors({
    //   name: false,
    //   devices: false,
    //   zones: false,
    //   file: false,
    // });
  }

  function verifyForm() {
    let verifiedErrors = {
      name: editedAnnouncement.name.length < 1,
      file: editedAnnouncement.file._id === "",
      devices: editedAnnouncement.devices.length === 0,
      zones: editedAnnouncement.zones.length === 0,
    };
    setErrors(verifiedErrors);

    if (verifiedErrors.name || verifiedErrors.file || verifiedErrors.devices || verifiedErrors.zones) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    verifyForm();
  }, [editedAnnouncement]);

  function handleSubmit(e) {
    e.preventDefault();

    try {
      let newAnnouncement = { ...editedAnnouncement };
      newAnnouncement.file = newAnnouncement.file._id;
      if (isEdit) {
        // update
        console.log("update");
        IntegrationAnnouncementService.update(announcement._id, newAnnouncement);
      } else {
        // add
        console.log("add");
        IntegrationAnnouncementService.add(newAnnouncement);
      }
      toast.success("Zapisano");
      onHide();
    } catch (err) {
      console.log(err);
      toast.error("Błąd komunikacji z serwerem");
    }
  }

  function handleNameEdit(e) {
    setEditedAnnouncement({ ...editedAnnouncement, name: e.target.value });
  }

  function handleFileChange(fileId) {
    if (fileId === "") {
      setEditedAnnouncement({ ...editedAnnouncement, file: { _id: "" } });
      return;
    }

    const file = files.find((file) => file._id === fileId);
    setEditedAnnouncement({ ...editedAnnouncement, file: file });
  }

  function handleRemoveDevice(device) {
    // let index = editedAnnouncement.devices.findIndex((dev) => dev !== device);
    let modifyedAnnouncement = null;
    if (editedAnnouncement?.devices.includes(device)) {
      const newDevices = editedAnnouncement.devices.filter((dev) => dev !== device);
      modifyedAnnouncement = { ...editedAnnouncement, devices: newDevices };
    } else {
      const newDevices = [...editedAnnouncement.devices, device];
      modifyedAnnouncement = { ...editedAnnouncement, devices: newDevices };
    }
    setEditedAnnouncement(modifyedAnnouncement);
  }

  function handleRemoveZone(zone) {
    // let index = editedAnnouncement.devices.findIndex((dev) => dev !== device);
    let modifyedAnnouncement = null;
    if (editedAnnouncement?.zones.includes(zone)) {
      const newZones = editedAnnouncement.zones.filter((dev) => dev !== zone);
      modifyedAnnouncement = { ...editedAnnouncement, zones: newZones };
    } else {
      const newZones = [...editedAnnouncement.zones, zone];
      modifyedAnnouncement = { ...editedAnnouncement, zones: newZones };
    }

    setEditedAnnouncement(modifyedAnnouncement);
  }

  function prepareDevicesOption() {
    const result = [];
    ["MA", "MAS"].forEach((type) => {
      const x = editedAnnouncement?.devices.find((dev_type) => dev_type === type);
      if (!x) {
        result.push({ type: type });
      }
    });
    return result;
  }

  return (
    <Modal
      show={show}
      onShow={handleShow}
      onEntering={handleShow}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {isEdit
              ? dictionary.integration.tabs_announcements.addEditModal.title_edit
              : dictionary.integration.tabs_announcements.addEditModal.title_add}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          {/* {this.renderInput("name", "Nazwa:")}
    
                {this.renderInput("description", "Opis:")} */}
          <Form.Group
            className="mb-3"
            controlId="name"
          >
            <Form.Label>Nazwa:</Form.Label>
            <Form.Control
              type="text"
              value={editedAnnouncement?.name}
              placeholder="Wprowadź nazwę"
              onChange={handleNameEdit}
              isValid={editedAnnouncement?.name && !errors.name}
              isInvalid={errors.name}
            />
          </Form.Group>

          <Form.Group
            className="mb-3"
            controlId="file"
          >
            <Form.Label>Plik:</Form.Label>
            <Form.Control
              as="select"
              value={editedAnnouncement?.file._id}
              onChange={(e) => handleFileChange(e.target.value)}
              style={{ borderColor: errors.file ? "red" : "" }}
              isValid={editedAnnouncement?.file._id && !errors.file}
              isInvalid={errors.file}
            >
              <option value={""}>Wybierz plik</option>
              {files.map((file) => (
                <option
                  key={file._id}
                  value={file._id}
                >
                  {file.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <div className="mt-3 flex-container flex-container__column flex-container__center flex-container__space-between">
            <Form.Label>Urządzeń:</Form.Label>
            <div className="tag-container flex-container__center">
              {["MA", "MAS"].map((device) => {
                let className = "selectable-tag__name__notSelected";
                if (editedAnnouncement?.devices.includes(device)) {
                  className = "selectable-tag__name";
                }

                return (
                  <div
                    className="tag"
                    key={device + "-device"}
                    onClick={() => handleRemoveDevice(device)}
                    style={{ borderColor: errors.devices ? "red" : "", borderWidth: errors.devices ? "1px" : "2px" }}
                  >
                    <div className={className}>{device}</div>
                  </div>
                );
              })}
            </div>
          </div>
          <hr></hr>
          <div className="mt-3 flex-container flex-container__column flex-container__center flex-container__space-between">
            <Form.Label>Strefy:</Form.Label>
            <div className="tag-container flex-container__center">
              {[1, 2, 3].map((zone) => {
                let className = "selectable-tag__name__notSelected";
                if (editedAnnouncement?.zones.includes(zone)) {
                  className = "selectable-tag__name";
                }

                return (
                  <div
                    className="tag"
                    key={zone + "-zone"}
                    onClick={() => handleRemoveZone(zone)}
                    style={{ borderColor: errors.zones ? "red" : "", borderWidth: errors.zones ? "1px" : "2px" }}
                  >
                    <div className={className}>{dictionary.integration.tabs_announcements.zoneMASettings[zone]}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={onHide}
            variant="primary"
          >
            Anuluj
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={errors.name || errors.file || errors.devices || errors.zones}
          >
            {isEdit ? "Edytuj" : "Dodaj"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddOrEditAnnouncementIntegrationModal;
