import React, { useState, useEffect } from "react";
import AnnouncementsTable from "./AnnouncementsTable";

const AnnouncementsIntegration = () => {
  return (
    <div>
      <AnnouncementsTable />
    </div>
  );
};

export default AnnouncementsIntegration;
