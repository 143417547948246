import axios from "axios";
import { toast } from "react-toastify";
//import * as Sentry from "@sentry/react";
// import logger from "./logService";
import {logout} from "./authService";

if(process.env.REACT_APP_PRODUCTION === "true")
{
  axios.defaults.baseURL = window.location.origin;
}
else
{
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
}

console.log("API URL: " + axios.defaults.baseURL);

axios.interceptors.response.use(null, (error) => {
  console.log(error)
  const expectedError =
    error.response && error.response.status > 400 && error.response.status < 500;

  if (error.response.status !== 400) {
    if (!expectedError) {
      // logger.log(error);
      //console.log("Interceptor called",error);
      toast.error("Nieoczekiwany błąd serwera");
    } else {
      if (error.response.status === 401) {
        if(error.request.responseURL.includes("integrations"))
        {
          return Promise.reject(error);
        }
        logout();
        window.location.reload(true);
        return null;
      } else  {
        // if(error.response.status !== 409)
        // toast.error("Nie ma takiego zasobu");
      }
    }
  }


  return Promise.reject(error);
});

function setJwt(jwt) {
  axios.defaults.headers.common["x-auth-token"] = jwt;
}

function setAccessCOntrolOrigin() {
  axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt,
  setAccessCOntrolOrigin,
};
