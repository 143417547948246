import React from "react";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";

import config from "../../../config.json";

export default function VolumeSettingsBlockMAS({
  device,
  value,
  onVolumeChange,
  onEnableChange,
  label,
}) {
  const disabled = !value.audioEnable ? true : false;

  return (
    <Table responsive="xs" style={{ width: "90%", margin: "auto" }}>
      <tbody>
        <tr>
          <th
            style={{
              textAlign: "right",
              minWidth: "100px",
              margin: "0",
              padding: "0",
            }}
          >
            <p className="m-2">{label}</p>
          </th>

          <td
            style={{
              textAlign: "center",
              minWidth: "200px",
              margin: "0",
              padding: "0",
              verticalAlign: "middle"
            }}
          >
            {/* <div className="m-2" style={{ width: "100%" }}> */}
              <input
                type="range"
                style={{ width: "90%" }}
                min="1"
                max="20"
                key="1"
                value={value.volume}
                onChange={({ currentTarget: input }) =>
                  onVolumeChange(input.value)
                }
                disabled={disabled}
                className="m-2"
              />
            {/* </div> */}
          </td>
          <td
            style={{
              textAlign: "center",
              width: "80px",
              margin: "0",
              padding: "0",
              verticalAlign: "middle"
            }}
          >
            <p className="mx-2 my-auto">{value.volume}</p>
          </td>

          <td 
            style={{ 
              textAlign: "center", 
              margin: "0", 
              padding: "0",
            }}
          >
            <Form.Check
              type="switch"
              id={device.sn + "en"}
              className="m-2"
              checked={value.audioEnable}
              onChange={(e) => onEnableChange(e.target.checked)}
            />
          </td>
        </tr>
        
      </tbody>
    </Table>
  );
}
