import config from "../../config.json";

const enableBeep = (beepType, device) => {
  switch (beepType) {
    case config.beepTypes.beepDetectionScenerio1_ID:
      return true;
    default:
      if (device.dev_type === "MKS") {
        if (beepType === config.beepTypes.beep1_ID) {
          return true;
        }
      }
      return false;
  }
};

export function beepTableGenerateAndFillWithData(ids, device, maDevice, files) {
  let beepTable = [];

  ids.forEach((x) => {
    const beepNew = {
      id: x,
      file: defaultFileName(x, files, device),
      to: [],
    };

    if (maDevice)
      maDevice.map((dev) => {
        const isMAS = dev.dev_type === "MAS";
        if (isMAS) {
          return beepNew.to.push({
            sn: dev.sn,
            enable: enableBeep(x, device),
          });
        } else {
          return beepNew.to.push({
            sn: dev.sn,
            spk1: config.defaultConf.beep.spk1,
            spk2: config.defaultConf.beep.spk2,
            spk3: config.defaultConf.beep.spk3,
            spk4: config.defaultConf.beep.spk4,
            spk5: config.defaultConf.beep.spk5,
            spk6: config.defaultConf.beep.spk6,
            spk7: config.defaultConf.beep.spk7,
            spk8: config.defaultConf.beep.spk8,
            spk9: config.defaultConf.beep.spk9,
            spk10: config.defaultConf.beep.spk10,
            spk11: config.defaultConf.beep.spk11,
            spk12: config.defaultConf.beep.spk12,
            enable: enableBeep(x, device),
          });
        }
      });

    if (device.conf && device.conf.beepTable) {
      const beepFromDB = device.conf.beepTable.find((beep) => beep.id === x);
      if (beepFromDB) {
        beepNew.file = beepFromDB.file;

        if (beepFromDB.to)
          beepFromDB.to.map((to) => {
            const index = beepNew.to.findIndex((x) => x.sn === to.sn);
            if (index !== -1) {
              if (to.spk1 != null && to.spk1 !== undefined) beepNew.to[index].spk1 = to.spk1;
              if (to.spk2 != null && to.spk2 !== undefined) beepNew.to[index].spk2 = to.spk2;
              if (to.spk3 != null && to.spk3 !== undefined) beepNew.to[index].spk3 = to.spk3;
              if (to.spk4 != null && to.spk4 !== undefined) beepNew.to[index].spk4 = to.spk4;
              if (to.spk5 != null && to.spk5 !== undefined) beepNew.to[index].spk5 = to.spk5;
              if (to.spk6 != null && to.spk6 !== undefined) beepNew.to[index].spk6 = to.spk6;
              if (to.spk7 != null && to.spk7 !== undefined) beepNew.to[index].spk7 = to.spk7;
              if (to.spk8 != null && to.spk8 !== undefined) beepNew.to[index].spk8 = to.spk8;
              if (to.spk9 != null && to.spk9 !== undefined) beepNew.to[index].spk9 = to.spk9;
              if (to.spk10 != null && to.spk10 !== undefined) beepNew.to[index].spk10 = to.spk10;
              if (to.spk11 != null && to.spk11 !== undefined) beepNew.to[index].spk11 = to.spk11;
              if (to.spk12 != null && to.spk12 !== undefined) beepNew.to[index].spk12 = to.spk12;
              if (to.enable != null && to.enable !== undefined) beepNew.to[index].enable = to.enable;
            }
            return;
          });
      }
    }
    beepTable.push(beepNew);
  });

  return beepTable;
}

const searchForFileIdByName = (files, name) => {
  if (files) {
    let result = files.find((file) => file.name === name);
    if (result !== null && result !== undefined) return result._id;
    return "";
  }
  return "";
};

const defaultFileName = (beep_id, files, dev) => {
  switch (dev.dev_type) {
    case "MK":
      if (beep_id === config.beepTypes.beepDetectionScenerio1_ID)
        return searchForFileIdByName(files, config.defaultConf.MK.detectionFileName);
      else if (beep_id >= 1 && beep_id <= 6) {
        return searchForFileIdByName(files, config.defaultConf.MK.beepFileName[beep_id - 1]);
      }
      return "";
    case "MKS":
      if (beep_id >= 1 && beep_id <= 6) {
        return searchForFileIdByName(files, config.defaultConf.MKS.beepFileName[beep_id - 1]);
      }
      return "";
    case "MS":
      if (beep_id === config.beepTypes.beepDetectionScenerio1_ID)
        return searchForFileIdByName(files, config.defaultConf.MS.detectionFileName);
      return "";
    default:
      return "";
  }
};

const activeRange = (distanceSensorType) => {
  switch (distanceSensorType) {
    case config.distanceSensorTypes.ceiling_ID:
      return config.defaultConf.MS.activeRange;
    case config.distanceSensorTypes.cashier_ID:
      return config.defaultConf.MK.activeRangeCash;
    case config.distanceSensorTypes.client_ID:
      return config.defaultConf.MK.activeRangeClient;
    default:
      return 80;
  }
};

const maxRange = (distanceSensorType) => {
  switch (distanceSensorType) {
    case config.distanceSensorTypes.ceiling_ID:
      return config.defaultConf.MS.activeMinRange;
    case config.distanceSensorTypes.cashier_ID:
      return config.defaultConf.MK.activeMinRangeCash;
    case config.distanceSensorTypes.client_ID:
      return config.defaultConf.MK.activeMinRangeClient;
    default:
      return 80;
  }
};

export function DistanceSensorTableGenerateAndFillWithDataMK(ids, device) {
  let distanceSensorTable = [];

  ids.forEach((x) => {
    const sensorNew = {
      id: x,
      maxRange: [maxRange(x), maxRange(x), maxRange(x), maxRange(x)],
      activeRange: [activeRange(x), activeRange(x), activeRange(x), activeRange(x)],
      enable: true,
      blockSensor: [false, false, false, false], //config.defaultConf.MK.blockSensor,
    };

    if (device && device.conf && device.conf.distanceSensorTable) {
      const sensorFromDB = device.conf.distanceSensorTable.find((sensor) => sensor.id === x);
      if (sensorFromDB) {
        sensorNew.maxRange = Array.isArray(sensorFromDB.maxRange)
          ? sensorFromDB.maxRange
          : [sensorFromDB.maxRange, 0, 0, 0];
        sensorNew.activeRange = Array.isArray(sensorFromDB.activeRange)
          ? sensorFromDB.activeRange
          : [sensorFromDB.activeRange, 0, 0, 0];
        sensorNew.enable = sensorFromDB.enable;
        if (sensorFromDB?.blockSensor) sensorNew.blockSensor = sensorFromDB.blockSensor;
      }
    }

    distanceSensorTable.push(sensorNew);
  });

  return distanceSensorTable;
}

export function DistanceSensorTableGenerateAndFillWithData(ids, device) {
  let distanceSensorTable = [];

  ids.forEach((x) => {
    const sensorNew = {
      id: x,
      maxRange: maxRange(x),
      activeRange: activeRange(x),
      enable: true,
      blockSensor: [false, false, false, false], //config.defaultConf.MK.blockSensor,
    };

    if (device && device.conf && device.conf.distanceSensorTable) {
      const sensorFromDB = device.conf.distanceSensorTable.find((sensor) => sensor.id === x);
      if (sensorFromDB) {
        sensorNew.maxRange = sensorFromDB.maxRange;
        sensorNew.activeRange = sensorFromDB.activeRange;
        sensorNew.enable = sensorFromDB.enable;
        if (sensorFromDB?.blockSensor) sensorNew.blockSensor = sensorFromDB.blockSensor;
      }
    }

    distanceSensorTable.push(sensorNew);
  });

  return distanceSensorTable;
}

export function DetectionTimeoutFillWithData(device) {
  let dt = config.defaultConf.MK.detectionTimeout;

  if (device && device.conf && device.conf.dt !== null && device.conf.dt !== undefined) {
    dt = Number(device.conf.dt);
  }

  return dt;
}

export function CeilingDetectionTimeoutFillWithData(device) {
  let dt = config.defaultConf.MS.detectionTimeout;

  if (device && device.conf && device.conf.dt !== null && device.conf.dt !== undefined) {
    dt = Number(device.conf.dt);
  }

  return dt;
}

export function sensorBlockedDetectionTimeoutFillWithData(device) {
  let bdt = config.defaultConf.MK.sensorBlockedDetectionTimeout;

  if (device && device.conf && device.conf.bdt !== null && device.conf.bdt !== undefined) {
    bdt = Number(device.conf.bdt);
  }

  return bdt;
}

export function clientDetectionTimeoutFillWithData(device) {
  let cdt = config.defaultConf.MK.clientDetectionTimeout;

  if (device && device.conf && device.conf.cdt !== null && device.conf.cdt !== undefined) {
    cdt = Number(device.conf.cdt);
  }

  return cdt;
}

export function AudioDeviceSettingsGenerateAndFillWithData(device) {
  let volume = 0;
  let audioEnable = 0;
  let spkVolume = config.defaultConf.MA.spkVolume;
  let spkName = config.defaultConf.MA.spkName;
  let spkAudioEnable = config.defaultConf.MA.spkAudioEnable;
  let spkZone = config.defaultConf.MA.spkZone;
  let playlistEnable = config.defaultConf.MA.playlistEnable;
  let playlistIMSEnable = config.defaultConf.MA.playlistIMSEnable;
  let spkPlaylistVolume = config.defaultConf.MA.spkPlaylistVolume;
  let spkPlaylistAudioEnable = config.defaultConf.MA.spkPlaylistAudioEnable;

  if (device.conf && device.conf.volume) {
    volume = Number(device.conf.volume);
  }

  if (device.conf && device.conf.audioEnable) {
    audioEnable = device.conf.audioEnable;
  }

  if (device.conf && device.conf.spkVolume) {
    spkVolume = device.conf.spkVolume;
  }

  if (device.conf && device.conf.spkName) {
    spkName = device.conf.spkName;
  }

  if (device.conf && device.conf.spkAudioEnable) {
    spkAudioEnable = device.conf.spkAudioEnable;
  }

  if (device.conf && device.conf.spkZone) {
    spkZone = device.conf.spkZone;
  }

  if (device.conf && device.conf.playlistEnable) {
    playlistEnable = device.conf.playlistEnable;
  }

  if (device.conf && device.conf.playlistIMSEnable) {
    playlistIMSEnable = device.conf.playlistIMSEnable;
  }

  if (device.conf && device.conf.spkPlaylistVolume) {
    spkPlaylistVolume = device.conf.spkPlaylistVolume;
  }

  if (device.conf && device.conf.spkPlaylistAudioEnable) {
    spkPlaylistAudioEnable = device.conf.spkPlaylistAudioEnable;
  }

  return {
    volume,
    audioEnable,
    spkVolume,
    spkName,
    spkZone,
    spkAudioEnable,
    playlistEnable,
    playlistIMSEnable,
    spkPlaylistVolume,
    spkPlaylistAudioEnable,
  };
}
//MAS
export function AudioDeviceVOCOVOSettingsGenerateAndFillWithData(device) {
  let volume = config.defaultConf.MAS.volume;
  let audioEnable = config.defaultConf.MAS.audioEnable;
  

  if (device.conf && device.conf.volume) {
    volume = Number(device.conf.volume);
  }

  if (device.conf && device.conf.audioEnable) {
    audioEnable = device.conf.audioEnable;
  }

  return {
    volume,
    audioEnable,
  };
}

//MD
export function timeFillWithData(device, paramName, dev_type) {
  let time = config.defaultConf[dev_type][paramName];

  if (device && device.conf && device.conf[paramName] !== null && device.conf[paramName] !== undefined) {
    time = Number(device.conf[paramName]);
  }

  return time;
}

export function boolFillWithData(device, paramName, dev_type) {
  let value = config.defaultConf[dev_type][paramName];

  if (device && device.conf && device.conf[paramName] !== null && device.conf[paramName] !== undefined) {
    value = device.conf[paramName];
  }

  return value;
}
