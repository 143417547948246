import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { ToastContainer } from "react-toastify";

import NavBar from "./components/navbar";
import NotFound from "./components/notFound";
import LoginForm from "./components/loginForm";
import RegisterForm from "./components/registerForm";

import AuthDevices from "./components/authDevices";
import AuthDevicesUpdate from "./components/authDevicesUpdate";
import UnauthDevices from "./components/unauthDevices";
import Shops from "./components/shops";
import ShopForm from "./components/shopForm";
import ShopView from "./components/shopView";
import ShopsImport from "./components/shopsImport";
import Files from "./components/files";
import FwFiles from "./components/fwFiles";
import Users from "./components/users";
import Tags from "./components/tags";
import ApiSettings from "./components/apiSettings";
import RestartSettings from "./components/RestartSettings";
import WifiSettings from "./components/wifiSettings";
import Integration from "./components/Integration";
import LogView from "./components/logView";
import Home from "./components/home";
import Logout from "./components/logout";
import ProtectedRoute from "./components/common/protectedRoute";

import { getCurrentUser, isAdmin, isAdminOrIsService, isService } from "./services/authService";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import MusicManagement from "./components/musicManagement";
import MusicInShops from "./components/MusicInShops";
import AudioplayerLogView from "./components/audioplayerLogView";

export default class App extends Component {
  state = {};

  componentDidMount() {
    this.setState({ user: getCurrentUser() });
  }

  GenerateProtectedRouteWithUser(path, Component) {
    const user = this.state.user;
    return (
      <ProtectedRoute
        path={path}
        render={(props) => (
          <Component
            {...props}
            user={user}
          />
        )}
      />
    );
  }

  render() {
    const user = this.state.user;

    return (
      <React.Fragment>
        <NavBar user={user} />
        <Container
          className="mx-0 px-0 w-100 h-100"
          style={{ maxWidth: "100%", width: "100%" }}
        >
          <div>
            <ToastContainer />
          </div>
          {/* <Jumbotron> */}
          <Switch>
            <Route
              path="/login"
              component={LoginForm}
            />
            <Route
              path="/logout"
              component={Logout}
            />

            <ProtectedRoute
              path="/register/:id"
              render={(props) => {
                if (!isAdmin()) return <Redirect to="/" />;
                return <RegisterForm {...props} />;
              }}
            />

            <ProtectedRoute
              path="/register"
              render={(props) => {
                if (!isAdmin()) return <Redirect to="/" />;
                return <RegisterForm {...props} />;
              }}
            />

            <ProtectedRoute
              path="/shops/:id"
              component={ShopForm}
            />

            <ProtectedRoute
              path="/shops"
              component={Shops}
            />

            <ProtectedRoute
              path="/shopView/:id"
              component={ShopView}
            />

            <ProtectedRoute
              path="/shopsImport"
              component={ShopsImport}
            />

            <ProtectedRoute
              path="/logs"
              component={LogView}
            />

            <ProtectedRoute
              path="/files"
              component={Files}
            />

            <ProtectedRoute
              path="/fwFiles"
              component={FwFiles}
            />

            {/*<ProtectedRoute path="/musciManagement" component={MusicManagement}/>*/}

            <ProtectedRoute
              path="/playlisty"
              render={(props) => {
                if (isAdmin() === false) return <Redirect to="/" />;
                return <MusicManagement {...props} />;
              }}
            />

            <ProtectedRoute
              path="/musicinshops"
              render={(props) => {
                if (isAdmin() === false) return <Redirect to="/" />;
                return <MusicInShops {...props} />;
              }}
            />

            <ProtectedRoute
              path="/audioplayerHistory"
              render={(props) => {
                if (isAdmin() === false) return <Redirect to="/" />;
                return <AudioplayerLogView {...props} />;
              }}
            />

            <ProtectedRoute
              path="/logs/:shopId"
              component={LogView}
            />

            <ProtectedRoute
              path="/wifiSettings"
              render={(props) => {
                if ((isAdmin() && isService()) === false) return <Redirect to="/" />;
                return <WifiSettings {...props} />;
              }}
            />

            <ProtectedRoute
              path="/settingsApi"
              render={(props) => {
                if ((isAdmin() && isService()) === false) return <Redirect to="/" />;
                return <ApiSettings {...props} />;
              }}
            />

            <ProtectedRoute
              path="/restartSettings"
              render={(props) => {
                if ((isAdmin() && isService()) === false) return <Redirect to="/" />;
                return <RestartSettings {...props} />;
              }}
            />

            <ProtectedRoute
              path="/integration"
              render={(props) => {
                if ((isAdmin() && isService()) === false) return <Redirect to="/" />;
                return <Integration {...props} />;
              }}
            />

            <ProtectedRoute
              path="/devicesupdate"
              render={(props) => {
                if ((isAdmin() && isService()) === false) return <Redirect to="/" />;
                return <AuthDevicesUpdate {...props} />;
              }}
            />

            <ProtectedRoute
              path="/users"
              render={(props) => {
                if (!isAdmin()) return <Redirect to="/" />;
                return <Users {...props} />;
              }}
            />

            <ProtectedRoute
              path="/tags"
              render={(props) => {
                if (!isAdmin()) return <Redirect to="/" />;
                return <Tags {...props} />;
              }}
            />

            <ProtectedRoute
              path="/authdevices"
              component={AuthDevices}
            />

            <ProtectedRoute
              path="/unauthdevices"
              render={(props) => {
                if (!isAdminOrIsService()) return <Redirect to="/" />;
                return <UnauthDevices {...props} />;
              }}
            />

            <ProtectedRoute
              path="/home"
              component={Shops}
            />

            <Route
              path="/not-found"
              component={NotFound}
            />

            <Redirect
              from="/"
              exact
              to="/shops"
            />
            <Redirect to="/not-found" />
          </Switch>
          {/* </Jumbotron> */}
        </Container>
      </React.Fragment>
    );
  }
}
