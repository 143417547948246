import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import { CardDeck } from "react-bootstrap";
import { toast } from "react-toastify";

// import { client as wsClient } from "websocket";

import ShopContext from "../contexts/shopContext";
import CardDeviceMK from "./common/cardDeviceMK";
import CardDeviceMKS from "./common/cardDeviceMKS";
import CardDeviceMS from "./common/cardDeviceMS";
import CardDeviceMA from "./common/cardDeviceMA";
import CardDeviceMD from "./common/cardDeviceMD";
import CardDeviceMAS from "./common/cardDeviceMAS";

import ConfirmationModalContextProvider from "./common/modalConfirmationContext";
import ConfirmationButton from "./common/confirmationButton";

import dictionary from "../PL.json";
import config from "../config.json";

import * as AuthDeviceService from "../services/authDeviceService";
import ShopService from "../services/shopService";
import TagService from "../services/tagService";

import * as FileService from "../services/fileService";
import WifiService from "../services/wifiService";


import * as wsCommunication from "../utils/wsCommunication";
import { isAdminOrIsService } from "../services/authService";

let ws; // = new WebSocket(process.env.REACT_APP_WSS_URL + "/live", "ROSS_APP");

class ShopView extends Component {
  state = {
    authDevices: [],
    onlineDevices: [],
    allDevices: [],
    maDevices: [],
    files: [],
    shop: [],
    tags: [],
    wifis: [],
    shopId: "",
  };

  // ws = null;
  timer = null;
  timerOnlineMonitor = null;
  wsTimeout = 250;

  async componentDidMount() {
    const shopId = this.props.match.params.id;

    this.connect();

    if (!shopId) return;

    if (shopId === "new") return this.props.history.replace("/shops");

    await this.getDataFromServer();

    this.setState({ shopId });
  }

  getDataFromServer = async () => {
    const shopId = this.props.match.params.id;
    try {
      //Pobranie szczegółów dotyczących sklepu
      const { data: shop } = await ShopService.get(
        shopId
      );
      
      if (!shop) {
        return this.props.history.replace("/not-found");
      }
      this.setState({ shop });

      // Pobranie wszystkich urządzeń podłączonych do sklepu
      const { data: authDevices } = await AuthDeviceService.getByShopId(
        shop._id
      );

      // Wyfiltrowanie urządzeń audio
      const maDevices = authDevices.filter(
        (device) => (device.dev_type === "MA") || (device.dev_type === "MAS")
      );

      // Stworzenie listy urządzeń z parametrem online na potrzeby monitorowania ich obecności
      const onlineDevices = [];
      authDevices.map((device) => {
        return onlineDevices.push({
          sn: device.sn,
          online: false,
          timestamp: Date.now(),
          report: null,
          msize: 0,
          type: "--",
          fw: "-.-.-",
          hw: "----",
          ip: "----",
          rssi: "----",
          heap: "----",
        });
      });

      // pobranie listy plików dzwiękowych dostępnych na serwerze
      const { data: files } = await FileService.getAll();

      const { data: tags } = await TagService.getAll();

      const { data: wifis} = await WifiService.getAllForShop(shopId);

      this.setState({ authDevices, onlineDevices, maDevices, files, tags, wifis });
    } catch (err) {
      return this.props.history.replace("/shops");
    }
  };

  handleDeviceDataChange = (device,changedElements) => {
    const {authDevices} = this.state;
    if(typeof changedElements === 'object')
    {
      const index = authDevices.findIndex((authDevice) => authDevice._id === device._id);
      if(index === -1) return;
      if(changedElements.length < 0) return;

      changedElements.forEach(element => {
        authDevices[index][element] = device[element];
      })

      this.setState({authDevices});

    }
  }

  handleEditShop = (event, shopId) => {
    event.stopPropagation();
    this.props.history.push("/shops/" + shopId);
  };

  connect = () => {
    ws = null;

    if(process.env.REACT_APP_PRODUCTION === "true")
    {
      const protocol = (window.location.protocol === "https:") ? "wss:" : "ws:"; // window.location
      ws = new WebSocket( protocol + "//" + window.location.host + "/api/live", "ROSS_APP");
    }
    else
    {
        ws = new WebSocket(process.env.REACT_APP_WSS_URL + "/api/live", "ROSS_APP");
    }
    ws.onopen = () => {
      console.log("connected websocket main component");
      let response = {};
      response.to = "ROSS_DEV";
      response.localisation = "123";

      ws.send(JSON.stringify(response));

      this.timerOnlineMonitor = setInterval(this.onlineMonitor, 10000);
    };

    ws.onmessage = (message) => {
      const _id = this.props.match.params.id;
      const { onlineDevices, allDevices } = this.state;
      const data = JSON.parse(message.data);

      if (data && data.shop_id === _id) {
        if (data.sn) {
          let inShop = false;
          onlineDevices.forEach((device) => {
            if (device.sn === data.sn) {
              inShop = true;
              device.online = true;
              device.timestamp = Date.now();
              if (data.report) device.report = data.report;
              if (data.type) {
                console.log(data);
                device.type = data.type;
                device.fw = data.fw;
                device.hw = data.hw;
                device.msize = data.msize;
                device.ip = data.ip;
                device.rssi = data.rssi;
                device.heap = data.heap;
                device.liquidLevel = data.liquidLevel;
                device.audioplayerInfo = data.audioplayerInfo;
              }
            }
          });
          if (this.state.shop.name === "9999") {
            if (!inShop) {
              allDevices.forEach((device) => {
                if (device.sn === data.sn) {
                  inShop = true;
                  device.online = true;
                  device.timestamp = Date.now();
                  if (data.type) {
                    device.type = data.type;
                    device.fw = data.fw;
                    device.hw = data.hw;
                    device.msize = data.msize;
                    device.ip = data.ip;
                    device.rssi = data.rssi;
                    device.heap = data.heap;
                    device.liquidLevel = data.liquidLevel;
                  }
                }
              });

              if (!inShop) {
                allDevices.push({
                  sn: data.sn,
                  online: false,
                  timestamp: Date.now(),
                  report: null,
                  msize: data.msize ? data.msize : 0,
                  type: data.type ? data.type : "--",
                  fw: data.fw ? data.fw : "-.-.-",
                  hw: data.hw ? data.hw : "----",
                });
              }
            }
          }
          this.setState({ onlineDevices, allDevices });
        }
      }
    };

    ws.onclose = (e) => {
      console.log(
        `Socket is closed. Reconnect will be attempted in ${Math.min(
          10000 / 1000,
          (this.timeout + this.timeout) / 1000
        )} second.`,
        e.reason
      );

      this.timeout = this.timeout + this.timeout; //increment retry interval
      clearTimeout(this.timer);
      this.timer = setTimeout(this.check, Math.min(10000, this.timeout)); //call check function after timeout
    };

    ws.onerror = (err) => {
      console.error(
        "Socket encountered error: ",
        err.message,
        "Closing socket"
      );

      if (ws.readyState === WebSocket.OPEN) {
        ws.close();
      }
    };
  };

  onlineMonitor = () => {
    const { onlineDevices, shopId } = this.state;

    wsCommunication.broadcastMessage(this.handleMessageToSent);

    onlineDevices.forEach((device) => {
      // console.log("Różnica czasu: " + (Date.now() - device.timestamp));
      if (Date.now() - device.timestamp > 25000) {
        device.online = false;
      }
    });

    this.setState(onlineDevices);
  };

  check = () => {
    if (!ws || ws.readyState === WebSocket.CLOSED) this.connect(); //check if websocket instance is closed, if so call `connect` function.
  };

  componentWillUnmount() {
    ws.close();

    clearTimeout(this.timer);
    clearInterval(this.timerOnlineMonitor);
  }

  handleMessageToSent = (message) => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      const { shopId } = this.state;
      message.shop = shopId;
      ws.send(JSON.stringify(message));
    }
  };

  handleConfToSent(message) {}

  handleDelete = async (event, authDevice) => {
    if (event) event.stopPropagation();

    const { data } = await AuthDeviceService.remove(authDevice._id);

    const { data: authDevices } = await AuthDeviceService.getAll();

    this.setState({ authDevices });
  };

  getOnlineDeviceBySN = (sn) => {
    const { onlineDevices } = this.state;

    const device = onlineDevices.find((device) => device.sn === sn);
    return device;
  };

  resetAll = () => {
    // if (confirm("Press a button!")) {
    wsCommunication.demoMessage(
      "",
      { action: "broadcast_reset" },
      undefined,
      this.handleMessageToSent
    );

    setTimeout(() => {
      const { onlineDevices } = this.state;

      const result = onlineDevices.map((device) => {
        return {
          sn: device.sn,
          online: false,
          timestamp: Date.now(),
          report: null,
          msize: 0,
          type: "--",
          fw: "-.-.-",
          hw: "----",
          ip: "----",
          rssi: "----",
          heap: "----",
          liquidLevel: -1,
        };
      });

      this.setState({ onlineDevices: result });
    }, 5000);
    // }
  };

  render() {
    const { shop, maDevices, onlineDevices, allDevices, files } = this.state;

    return (
      <ShopContext.Provider
        value={{
          authDevices: this.state.authDevices,
          onlineDevices: this.state.onlineDevices,
          maDevices: this.state.maDevices,
          files: this.state.files,
          tags: this.state.tags,
          wifis: this.state.wifis,
        }}
      >
        <ConfirmationModalContextProvider>
          <React.Fragment>
            <Breadcrumb style={{ backgroundColor: "#DDDDDD" }}>
              <Breadcrumb.Item style={{ marginTop: 3, marginLeft: 3, marginBottom: 3, marginRight: 0 }} href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item style={{ marginTop: 3, marginLeft: 3, marginBottom: 3, marginRight: 0 }} href="/shops">Sklepy</Breadcrumb.Item>
              <Breadcrumb.Item style={{ marginTop: 3, marginLeft: 3, marginBottom: 3, marginRight: 0 }} active>{shop.name + " - " + shop.city}</Breadcrumb.Item>
            </Breadcrumb>
            <Container>
              {shop.name === "9999" && (
                <table className="table table-stripped">
                  <tbody>
                    {allDevices.map((device) => {
                      return (
                        <tr key={device.sn + "_rest"} className="even">
                          <td>SN: {device.sn} </td>
                          <td>FW: {device.fw} </td>
                          <td>HW: {device.hw} </td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
              {this.state.authDevices.map((device) => {
                if (device.dev_type === "MK") {
                  return (
                    <CardDeviceMK
                      id={device._id}
                      key={device._id}
                      device={device}
                      files={files}
                      maDevices={maDevices}
                      onlineDeviceData={this.getOnlineDeviceBySN(device.sn)}
                      onMessageToSent={this.handleMessageToSent}
                      forceDataRefresh={this.getDataFromServer}
                      // deviceDataChange={this.handleDeviceDataChange}
                    />
                  );
                }
                return null;
              })}
              {this.state.authDevices.map((device) => {
                if (device.dev_type === "MA") {
                  return (
                    <CardDeviceMA
                      id={device._id}
                      key={device._id}
                      device={device}
                      onlineDeviceData={this.getOnlineDeviceBySN(device.sn)}
                      onMessageToSent={this.handleMessageToSent}
                      forceDataRefresh={this.getDataFromServer}
                    />
                  );
                }
                return null;
              })}

              {this.state.authDevices.map((device) => {
                if (device.dev_type === "MAS") {
                  return (
                    <CardDeviceMAS
                      id={device._id}
                      key={device._id}
                      device={device}
                      onlineDeviceData={this.getOnlineDeviceBySN(device.sn)}
                      onMessageToSent={this.handleMessageToSent}
                      forceDataRefresh={this.getDataFromServer}
                    />
                  );
                }
                return null;
              })}

              {this.state.authDevices.map((device) => {
                if (device.dev_type === "MS") {
                  return (
                    <CardDeviceMS
                      id={device._id}
                      key={device._id}
                      device={device}
                      files={files}
                      maDevices={maDevices}
                      onlineDeviceData={this.getOnlineDeviceBySN(device.sn)}
                      onMessageToSent={this.handleMessageToSent}
                      forceDataRefresh={this.getDataFromServer}
                    />
                  );
                }

                return null;
              })}

              {this.state.authDevices.map((device) => {
                if (device.dev_type === "MKS") {
                  return (
                    <CardDeviceMKS
                      id={device._id}
                      key={device._id}
                      device={device}
                      files={files}
                      maDevices={maDevices}
                      onlineDeviceData={this.getOnlineDeviceBySN(device.sn)}
                      onMessageToSent={this.handleMessageToSent}
                      forceDataRefresh={this.getDataFromServer}
                    />
                  );
                }


                return null;
              })}

              {this.state.authDevices.map((device) => {
                if (device.dev_type === "MD") {

                  return (
                      <CardDeviceMD
                          id={device._id}
                          key={device._id}
                          device={device}
                          onlineDeviceData={this.getOnlineDeviceBySN(device.sn)}
                          onMessageToSent={this.handleMessageToSent}
                          forceDataRefresh={this.getDataFromServer}
                          // deviceDataChange={this.handleDeviceDataChange}
                      />
                  );
                }
                return null;
              })}
              {isAdminOrIsService && (
                <div className="d-flex justify-content-center">
                  <div className="p-2 bd-highlight">
                    <ConfirmationButton
                      label={dictionary.shopView.resetButton_confirmLabel}
                      yesLabel={dictionary.shopView.resetButton_confirmYesLabel}
                      noLabel={dictionary.shopView.resetButton_confirmNoLabel}
                      variant="danger"
                      onClick={this.resetAll}
                    >
                      {dictionary.shopView.resetButton_label}
                    </ConfirmationButton>
                  </div>
                  <div className="p-2 bd-highlight">
                    <Button
                        variant="warning"
                        onClick={(e) => this.handleEditShop(e, this.state.shopId)}
                    >
                      Edytuj Ustawienia sklepu
                    </Button>
                  </div>
                </div>
              )}
            </Container>
          </React.Fragment>
        </ConfirmationModalContextProvider>
      </ShopContext.Provider>
    );
  }
}

export default ShopView;
