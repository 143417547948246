import React, {Component} from "react";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";

import Container from "react-bootstrap/Container";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Shop from "../services/shopService";
import {isAdmin} from "../services/authService";


import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Pagination from "./common/pagination";
import {searchInShopTable} from "../utils/search";
import {paginate} from "../utils/paginate";

import ShopsTable from "./shopsTable";

import _ from "lodash";
import SearchBox from "./common/searchBox";
import {findShopsInUseDuringLastHour, findShopsWithDevices} from "../utils/select";

const containerStyle = {
    width: "100%",
    height: "400px",
    margin: "auto",
};

class Shops extends Component {
    state = {
        shops: [],
        pageSize: 100,
        currentPage: 1,
        sortColumn: {path: "name", order: "asc"},
        searchQuery: "",
        inUse: false,
        inUseLastHour: false,
    };

    async componentDidMount() {
        console.log("mount")
        await this.getShopData(this.state.inUse, this.state.inUseLastHour);
    }

    getShopData = async (inUse, inUseLastHour) => {
        try {
            if (inUse) {
                const {data} = await Shop.getAllShopsAndDevices();

                const shops = findShopsWithDevices(data.allShops, data.devices);

                this.setState({shops});
            } else if (inUseLastHour) {
                // const {data: shops} = await Shop.getAllWithDevicesActiveLastHour();
                const {data} = await Shop.getAllShopsAndDevices();

                const shops = findShopsInUseDuringLastHour(data.allShops, data.devices);

                this.setState({shops});
            } else {
                const {data: shops} = await Shop.getAll();
                this.setState({shops});
            }
        } catch (err) {
            // toast.error("Błąd serwera!!!");
            this.props.history.push("/not-found");
        }
    };

    handleEdit = (event, shop) => {
        event.stopPropagation();
        this.props.history.push("/shops/" + shop._id);
    };

    handleRowClick = (shop) => {
        this.props.history.push("/shopView/" + shop._id);

        // const { data } = await http.delete(
        //   config.apiUrl + "/unauthdevices/" + unauthDevice._id
        // );
        // const { data: unauthDevices } = await http.get(
        //   config.apiUrl + "/unauthdevices/"
        // );
        // this.setState({unauthDevices});
    };

    handlePageChange = (page) => {
        this.setState({currentPage: page});
    };

    handleSort = (sortColumn) => {
        if (sortColumn.path === "") return;

        this.setState({sortColumn});
    };

    getPagedData = () => {
        const {pageSize, currentPage, shops: allShops, sortColumn, searchQuery} = this.state;

        let filtered = allShops;

        if (searchQuery) {
            filtered = searchInShopTable(allShops, ["name", "city", "adress"], searchQuery);
        }

        const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

        const shops = paginate(sorted, currentPage, pageSize);

        return {totalCount: filtered.length, data: shops};
    };

    handleSearch = (query) => {
        this.setState({
            searchQuery: query,
            currentPage: 1,
        });
    };

    handleInUseChange = async ({target}) => {
        this.setState({
            inUseLastHour: false,
            inUse: target.checked,
        });

        await this.getShopData(target.checked, false);
    };

    handleInUseLastHourChange = async ({target}) => {
        this.setState({
            inUse: false,
            inUseLastHour: target.checked,
        });

        await this.getShopData(false, target.checked);
    };


    render() {
        const count = this.state.shops.length;
        const {pageSize, currentPage, sortColumn, searchQuery, user, shops: allShops} = this.state;

        // if (count === 0) {
        //   return (
        //     <React.Fragment>
        //       <p>Nie ma urządzeń oczekujących na akceptację!!!</p>
        //     </React.Fragment>
        //   );
        // }

        const {totalCount, data: shops} = this.getPagedData();

        return (
            <React.Fragment>
                <Breadcrumb style={{backgroundColor: "#DDDDDD"}}>
                    <Breadcrumb.Item style={{marginTop: 3, marginLeft: 3, marginBottom: 3, marginRight: 0}} active>
                        Sklepy
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Container>
                    <Row sm={12} className="pt-2 flex-container__center">
                        <Col sm="2">
                            {isAdmin() && (
                                <Link className="btn btn-primary margin-bottom-3" to="/shops/new">
                                    Dodaj
                                </Link>
                            )}
                        </Col>
                        <Col sm="3" style={{textAlign: "center"}}>
                            <label>
                                Sklepy tylko z urządzeniami:
                                <input
                                    className="m-2"
                                    name="inUse"
                                    type="checkbox"
                                    checked={this.state.inUse}
                                    onChange={this.handleInUseChange}
                                />
                            </label>
                        </Col>
                        <Col sm="3" style={{textAlign: "center"}}>
                            <label>
                                tylko z aktywnymi urządzeniami
                                <input
                                    className="m-2"
                                    name="inUseLastHour"
                                    type="checkbox"
                                    checked={this.state.inUseLastHour}
                                    onChange={this.handleInUseLastHourChange}
                                />
                            </label>
                        </Col>
                        <Col sm="2" style={{textAlign: "center"}}>
                            <label>Liczba sklepów: {allShops.length}</label>
                        </Col>
                        <Col sm="2" >
                            {isAdmin() && (
                                <Link className="btn btn-primary " to="/shopsImport">
                                    Zaimportuj z pliku .xlsx
                                </Link>
                            )}
                        </Col>
                    </Row>

                    <Row className={"pt-3 d-flex flex-row align-items-center justify-content-center"}>
                        <Col ms="12" xs="12" className="w-75">
                            <SearchBox  value={searchQuery} onChange={this.handleSearch}/>
                        </Col>
                    </Row>
                    <Row className="mt-2 mb-0">
                        <Col ms="12" xs="12" className="mt-0 mb-0">
                            <Pagination
                                itemsCount={totalCount}
                                pageSize={pageSize}
                                currentPage={currentPage}
                                onPageChange={this.handlePageChange}
                            />
                        </Col>
                    </Row>
                    <Row className={"mt-1"}>
                        <Col ms="12" xs="12">
                            <ShopsTable
                                shops={shops}
                                sortColumn={sortColumn}
                                onEdit={this.handleEdit}
                                onAdd={this.handleAdd}
                                onSort={this.handleSort}
                                onRowClick={this.handleRowClick}
                            />
                        </Col>
                    </Row>

                    <Row className="mb-1">
                        <Col ms="12" xs="12">
                            <Pagination
                                itemsCount={totalCount}
                                pageSize={pageSize}
                                currentPage={currentPage}
                                onPageChange={this.handlePageChange}
                            />
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        );
    }
}

export default Shops;
