import React, { Component } from "react";
import { Redirect } from "react-router";

import Container from "react-bootstrap/Container";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { toast } from "react-toastify";

import config from "../config.json";

import ApiNameView from "./common/apiNameView";

import ApiSettingsService from "../services/apiSettingsService";
import { isAdmin } from "../services/authService";

import ConfirmationModalContextProvider from "./common/modalConfirmationContext";

import _ from "lodash";
import ApiKey from "./common/apiKey";
import dictionary from "../PL.json";
import AnnouncementsTable from "./AnnouncementsTable";
import AnnouncementsIntegration from "./AnnouncmentsIntegration";

class Integration extends Component {
  state = {
    api_key: {},
  };

  async componentDidMount() {
    // await this.refreshData();
  }

  refreshData = async () => {
    try {
      const { data: apiSettings } = await ApiSettingsService.getAll();
      this.setState({ apiSettings });
    } catch (err) {
      toast.error("Błąd serwera!!!");
      this.props.history.push("/not-found");
    }
  };

  render() {
    if (!isAdmin()) return <Redirect to="/shops" />;
    const { apiSettings } = this.state;

    return (
      <React.Fragment>
        <ConfirmationModalContextProvider>
          <Breadcrumb style={{ backgroundColor: "#DDDDDD" }}>
            <Breadcrumb.Item
              style={{
                marginTop: 3,
                marginLeft: 3,
                marginBottom: 3,
                marginRight: 0,
              }}
              href="/"
            >
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item
              style={{
                marginTop: 3,
                marginLeft: 3,
                marginBottom: 3,
                marginRight: 0,
              }}
              active
            >
              Konfiguracja
            </Breadcrumb.Item>
            <Breadcrumb.Item
              style={{
                marginTop: 3,
                marginLeft: 3,
                marginBottom: 3,
                marginRight: 0,
              }}
              active
            >
              Integracja
            </Breadcrumb.Item>
          </Breadcrumb>
          <Container>
            <ApiKey
              key={"apiKey"}
              enable={false}
              model={"apiModel"}
              onRefreshData={this.refreshData}
            ></ApiKey>

            <Tabs
              defaultActiveKey="announcement"
              id="integration-tabs"
              className="mb-3 custom-integration-tab"
              justify
            >
              <Tab
                eventKey="announcement"
                title={dictionary.integration.tabs_announcements.title}
              >
                <AnnouncementsIntegration />
              </Tab>
              <Tab
                eventKey="other"
                title="..."
                disabled
              >
                <div></div>
              </Tab>
            </Tabs>
          </Container>
        </ConfirmationModalContextProvider>
      </React.Fragment>
    );
  }
}

export default Integration;
