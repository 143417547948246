import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { toast } from "react-toastify";

import SettingsService from "../../services/settingsService";
import ConfirmationIcon from "./confirmationIcon";
import ConfirmationSwitch from "./confirmationSwitch";

import dictionary from "../../PL.json";

// import * as AuthDeviceService from "../../../services/authDeviceService";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faSave,
  faCircleInfo,
  faTrash,
  faXmark,
  faShuffle,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import ConfirmationIconWithToolTip from "./confirmationIconWithToolTip";

const KEY = "integration_api_key";

export default function ApiKey({ enable, model, onRefreshData }) {
  const [api, setApi] = useState(null);
  const [value, setValue] = useState({ name: KEY, value: {api_key: "none"}, enable: false });
  const [en, setEn] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [edit, setEdit] = useState(false);
  const [visibility, setVisibility] = useState(false);

  async function fetchData() {
    try {
      let { data } = await SettingsService.getSetting(KEY);
      if (data === "") {
        setApi({
          name: KEY,
          value: {api_key: "none"},
          enable: false,
        });
      } else {
        setApi({
          name: data.name,
          value: data.value,
          enable: data.enable,
        });
        setValue({
          name: data.name,
          value: data.value,
          enable: data.enable,
        });
      }
    } catch (err) {
      toast.error("Błąd API KEY!!!");
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  // useEffect(() => {
  //   const en = enable !== null ? enable : false;
  //   setEn(en);
  // }, [enable]);

  const generateKey = () => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < 18; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    setValue({...value, value: {api_key: result}});
  };

  const clearChanges = () => {
    setValue({...api});
    setEdit(false);
  };

  const handleVisibility = async () => {
    setTimeout(() => {
      setVisibility(false);
    }, 2000);

    setVisibility(!visibility);
  };

  const handleSaveChanges = async () => {
    try {
        const { data } = await SettingsService.updateSetting(value.name,{
          name: value.name,
          enable: value.enable,
          value: {api_key: value.value.api_key},
        });
      setEdit(false);
      await fetchData();
      toast.success("Ustawienia zostały zmienione");
    } catch (err) {
      toast.error("Błąd komunikacji z serwerem");
    }
  };

  const handleEdit = (newValue) => {
    if (newValue.length < 500) setValue({...value, value: {api_key: newValue} });
  };

  const handleEnCheck = async (checked) => {
    // setEn(event.target?.checked);
    console.log(checked);
    try {
      const { data } = await SettingsService.updateSetting(value.name,{
        enable: checked,
        value: {api_key: infoDiffrent ?  api.value.api_key : value.value.api_key}
      });

    } catch (err) {
      toast.error("Błąd komunikacji z serwerem");
    }
    await fetchData();
  };

  const infoDiffrent = value?.value !== api?.value && value?.value.api_key !== "none";

  const handleActiveOrNot = () => {
    const style = {
      minWidth: "500px",
      borderRadius: "8px",
      backgroundColor: "rgba(170,170,170,0.5)",
    };

    if (enable) style.backgroundColor = "rgba(171, 209, 181,0.5)";

    return style;
  };

  return (
    <div
      className="py-3 my-3 "
      style={handleActiveOrNot()}
    >
      <Row className="flex-container flex-container__center w-100 m-2">
        <Col
          md="3"
          xs="12"
        >
          <Row className="mx-0 px-0 my-2 fw-bold h-100">
            <Col className="flex-container flex-container__center ">
              API KEY
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="tooltip">
                    <div>klucz autoryzacyjny</div>
                  </Tooltip>
                }
              >
                <div className="px-1 my-0">
                  <FontAwesomeIcon
                    onClick={() => setShowInfo(!showInfo)}
                    className="mx-0 my-0 icon__info"
                    icon={faCircleInfo}
                  />
                </div>
              </OverlayTrigger>
              :
            </Col>
          </Row>
        </Col>

        {edit ? (
          <React.Fragment>
            <Col
              className="px-0"
              md="6"
              xs="9"
            >
              <input
                style={{
                  width: "100%",
                  color: infoDiffrent ? "rgb(184,7,7)" : "black",
                }}
                max="500"
                value={value.value.api_key}
                onChange={(e) => handleEdit(e.currentTarget.value)}
              ></input>
            </Col>
          </React.Fragment>
        ) : (
          <Col
            className="px-0 fst-italic"
            style={{
              textAlign: "center",
              color: infoDiffrent ? "rgb(184,7,7)" : "black",
              fontWeight: infoDiffrent ? "bold" : "",
            }}
            md="6"
            xs="9"
          >
            <p className="p-0 m-0">{visibility ? value.value.api_key : "••••••••••"}</p>
          </Col>
        )}

        <Col
          xs="2"
          md="2"
        >
          {!edit && (
            <FontAwesomeIcon
              onClick={() => handleVisibility()}
              style={{ cursor: "pointer" }}
              className="tag__visible"
              icon={visibility ? faEyeSlash : faEye}
            />
          )}
          {edit && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip">
                  <div>{dictionary.integration.apiKey.tip_generate}</div>
                </Tooltip>
              }
            >
              <FontAwesomeIcon
                onClick={generateKey}
                className="tag__edit my-0"
                icon={faShuffle}
              />
            </OverlayTrigger>
          )}
          {!edit && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip">
                  <div>{dictionary.integration.apiKey.tip_edit}</div>
                </Tooltip>
              }
            >
              <FontAwesomeIcon
                onClick={() => setEdit(!edit)}
                className="tag__edit my-0"
                icon={faEdit}
              />
            </OverlayTrigger>
          )}

          {edit && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  <div>{dictionary.integration.apiKey.tip_cancel}</div>
                </Tooltip>
              }
            >
              <FontAwesomeIcon
                onClick={clearChanges}
                className="tag__delete my-0"
                icon={faXmark}
              />
            </OverlayTrigger>
          )}

          {infoDiffrent && (
            <ConfirmationIconWithToolTip
              placement="top"
              info={dictionary.integration.apiKey.tip_save}
              label={dictionary.integration.apiKey.saveButton_confirmLabel}
              yesLabel={dictionary.integration.apiKey.saveButton_confirmYesLabel}
              noLabel={dictionary.integration.apiKey.saveButton_confirmNoLabel}
              onClick={handleSaveChanges}
              className="tag__save"
              icon={faSave}
            />
          )}

          {/* {edit && api !== undefined && api !== null ? (
            <ConfirmationIcon
              label={dictionary.apiSettings.deleteButton_confirmLabel}
              yesLabel={dictionary.apiSettings.deleteButton_confirmYesLabel}
              noLabel={dictionary.apiSettings.deleteButton_confirmNoLabel}
              onClick={handleDelete}
              className="tag__save"
              icon={faTrash}
            />
          ) : null} */}
        </Col>
        <Col
          className="px-0"
          md="1"
          xs="1"
        >
          <ConfirmationSwitch
            label={
              value.enable
                ? dictionary.apiSettings.CheckSwitch_deactivateConfirmLabel
                : dictionary.apiSettings.CheckSwitch_activateConfirmLabel
            }
            yesLabel={dictionary.apiSettings.CheckSwitch_confirmYesLabel}
            noLabel={dictionary.apiSettings.CheckSwitch_confirmNoLabel}
            key={model.name}
            onChange={handleEnCheck}
            type="switch"
            checked={value.enable}
          />
        </Col>
      </Row>
    </div>
  );
}
